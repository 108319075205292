<template>
  <v-app id="app">
    <div class="bg-design-box bg-design-2"></div>
    <div class="bg-design-box bg-design-3"></div>
    <v-main class="bg-image">
      <v-container fluid class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <div class="text-center mb-4">
              <a href="//consark.ai">
                <img
                  src="/img/consark/logo.png"
                  class=""
                  style="max-width: 300px"
                  alt="consark-logo-image"
                />
              </a>
              <div class="body-1 font-weight-bold">
                Unified Controllership Platform
              </div>
            </div>
            <v-card
              transition="fade-transition"
              :class="{
                'shadow2-a pb-1 px-1': true,
                'pb-3 px-3': $vuetify.breakpoint.smAndUp,
              }"
              v-if="stage === 1"
              loader-height="2"
              :loading="loading"
              :disabled="loading"
            >
              <v-card-title primary-title class="">
                <div class="title">Forgot Password?</div>
              </v-card-title>
              <v-card-text>
                <div class="pb-3">Enter your email below.</div>
                <v-alert
                  type="error"
                  border="left"
                  v-if="error"
                  text
                  class="py-2"
                  >{{ error }}</v-alert
                >
                <v-text-field
                  type="text"
                  v-model="form.username"
                  label="Email"
                  class=""
                  dense
                  outlined
                  prepend-inner-icon="mdi-shield-account"
                  prefix=""
                  suffix=""
                  autofocus
                >
                </v-text-field>
                <div class="text-right">
                  <v-btn text color="mybtn" class="shadow-off" @click="$router.go(-1)" small
                    >Cancel</v-btn
                  >
                  <v-btn color="primary mybtn" class="shadow-off" @click="forgotPassword()" small
                    >Resend Password(Existing User)</v-btn
                  >
                  <v-btn color="primary mybtn" class="shadow-off" @click="resendPassword()" small
                      >Resend Password(New User)</v-btn
                    >
                </div>
                <!-- <div class="pt-5">
                  <v-divider></v-divider>
                  <div class="pt-5 body-1 text--darken-2">Forgot Username?</div>
                  <div>Contact your system administrator for further assistance.</div>
                </div> -->
              </v-card-text>
            </v-card>
            <v-card
              :class="{ 'shadow2-a pa-1': true, 'pa-3': $vuetify.breakpoint.smAndUp }"
              v-else-if="stage === 2"
            >
            <v-card-text class="text-center">
                <v-icon style="font-size: 70px !important" class="success--text"
                  >mdi-check-circle-outline</v-icon
                >
                <div class="">
                  We have sent an email to <b>{{ form.username }}</b>.<br/>
                  Please follow the same to reset your password
                </div>
              <!-- <v-card-title primary-title class="justify-center success--text pb-1">
                <div>
                  <div class="text-center">
                    <v-avatar size="80">
                      <v-icon color="success" size="80"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >
                    </v-avatar>
                  </div>
                  <div>We have sent an email to reset your password</div>
                </div>
              </v-card-title>
              <v-card-text class="text-center">
                <div class="pb-2" v-if="message">{{ message }}</div>
                <div v-if="error" class="pb-2 error--text body-1 text-center">
                  {{ error }}
                </div> -->
                <div class="pt-3">
                  <v-btn color="primary" elevation="0" to="/" small
                    ><v-icon left>mdi-home</v-icon>Go to Login</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
            <v-card
              :class="{ 'shadow2-a pa-1': true, 'pa-3': $vuetify.breakpoint.smAndUp }"
              v-else
            >
              <v-card-text class="text-center">
                <noaccess class="pb-3" />
                <!-- <div class="pt-3">
                  <v-btn color="primary" elevation="0" to="/"
                    ><v-icon left>mdi-home</v-icon>Go to home</v-btn
                  >
                </div> -->
              </v-card-text>
            </v-card>
            <!-- <v-card class="pa-4" v-else-if="stage === 2">
              <v-card-text class="text-center">
                <v-icon style="font-size: 70px !important" class="success--text"
                  >mdi-check-circle-outline</v-icon
                >
                <div class="title">Password changed successfully</div>
                <div v-if="error" class="pb-2 error--text body-1 text-center">
                  {{ error }}
                </div>
                <div class="pt-3">
                  <div class="pt-3" v-if="gotologin">
                    <div class="d-flex align-center justify-center"><v-btn class="mr-2" icon disabled loading x-small></v-btn>Redirecting to login...</div>
                  </div>
                  <v-btn color="primary" small to="/" v-else
                    ><v-icon left>mdi-home</v-icon>Go to home</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
            <v-card
              :class="{
                'shadow2-a pa-1': true,
                'pa-3': $vuetify.breakpoint.smAndUp,
              }"
              v-else
            >
              <v-card-text class="text-center">
                <noaccess class="pb-3" />
              </v-card-text>
            </v-card> -->
            <div class="text-center mt-4">
              <!-- remove logo <div class="Caption font-weight-bold">Powered By</div>
               <img
                src="/img/conserve/logotext-no-bg.webp"
                class=""
                style="max-width: 200px"
                alt="consark-image-logo-text-image"
              /> -->
               <div class="text-center mt-2"> 
                   <a href="https://consark.ai/policies/termsofuse/" target="_blank">
                      <v-btn class="shadow-off primary--text mr-2" outlined small>Terms of Use & Service</v-btn>
                  </a>
                   <a href="https://consark.ai/policies/privacypolicy/" target="_blank">
                  <v-btn class="shadow-off primary--text" outlined small
                    >Privicy Policy</v-btn>
                   </a>
                </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
// @ is an alias to /src
// import loader from '@/views/common/Loader';
import noaccess from "@/views/common/NoAccess";

export default {
  name: "branch",
  props: ["props"],
  components: {
    noaccess,
  },
  data: () => ({
    stage: 1,
    loading: false,
    form: {
      username: "",
    },
    message: "",
    error: "",
  }),
  created(){
    this.$store.commit("addLoading");
    this.$nextTick(() => {
      this.$store.commit("removeLoading");
    })
  },

  methods: {
    forgotPassword: function () {
      this.error = "";
      this.loading = true;
      this.axios
        .post("/v2/auth/forgotpassword/" + this.form.username)
        .then((x) => {
          if (x.data.status == "success") {
            this.message = x.data.message || "";
            this.stage = 2;
          } else {
            this.error = x.data.message || "Error!";
          }
        })
        .catch((e) => {
          this.error = e.message || "Error!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resendPassword: function () {
      this.error = "";
      this.loading = true;
      this.axios
        .post("/v2/auth/resendpassword",{
          username:this.form.username
        })
        .then((x) => {
          if (x.data.status == "success") {
            this.message = x.data.message || "";
            this.stage = 2;
          } else {
            this.error = x.data.message || "Error!";
          }
        })
        .catch((e) => {
          this.error = e.message || "Error!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style>
.mybtn+.mybtn {
  margin-left: 3px;
}
</style>
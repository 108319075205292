<template>
  <v-app id="app">
    <div class="bg-design-box bg-design-2"></div>
    <div class="bg-design-box bg-design-3"></div>
    <v-main class="bg-image">
      <v-container fluid class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <div class="text-center mb-4">
              <a href="//consark.ai">
                <img
                  src="/img/consark/logo.png"
                  class=""
                  style="max-width: 300px"
                  alt="consark-logo-image"
                />
              </a>
              <div class="body-1 font-weight-bold">
                Unified Controllership Platform
              </div>
            </div>
            <v-card
              :class="{
                'shadow2-a pb-1 px-1': true,
                'pb-3 px-3': $vuetify.breakpoint.smAndUp,
              }"
              loader-height="2"
              :loading="loading"
              :disabled="loading"
              v-if="stage === 1"
            >
              <v-card-text class="pb-1">
                <div class="title">Set Password</div>
                <div class="" v-if="!form.otp">
                  <strong>Email: </strong>{{ data.contactemail }}
                </div>
              </v-card-text>
              <v-card-text>
                <v-alert
                  type="error"
                  border="left"
                  v-if="error"
                  text
                  class="py-2"
                  >{{ error }}</v-alert
                >
                <div class="pb-3">Enter your new password below.</div>
                <v-text-field
                  v-model="form.email"
                  label="Email"
                  class="mb-3"
                  dense
                  hide-details
                  outlined
                  prepend-inner-icon="mdi-email"
                  prefix=""
                  suffix=""
                >
                </v-text-field>
                <v-text-field
                  v-if="!form.otp"
                  type="password"
                  v-model="form.oldpassword"
                  label="Old Password"
                  class="mb-3"
                  dense
                  hide-details
                  outlined
                  prepend-inner-icon="mdi-key-variant"
                  prefix=""
                  suffix=""
                >
                </v-text-field>
                <v-text-field
                  v-else-if="form.otp"
                  type="number"
                  v-model="form.otp"
                  label="OTP"
                  class="mb-3"
                  dense
                  hide-details
                  outlined
                  prepend-inner-icon="mdi-cellphone-key"
                  prefix=""
                  suffix=""
                >
                </v-text-field>
                <v-text-field
                  type="password"
                  v-model="form.password"
                  label="New Password"
                  @keyup="checkpolicy"
                  class="mb-3"
                  dense
                  hide-details
                  outlined
                  prepend-inner-icon="mdi-key-variant"
                  prefix=""
                  suffix=""
                >
                </v-text-field>
                <v-text-field
                  type="password"
                  v-model="form.repassword"
                  label="Re-enter New Password"
                  class="mb-3"
                  dense
                  hide-details
                  outlined
                  prepend-inner-icon="mdi-key-variant"
                  prefix=""
                  suffix=""
                >
                </v-text-field>
                <div class="d-flex align-start">
                  <v-icon color="error" class="mr-2 mt-1" v-if="!policymatch">mdi-alert-circle</v-icon>
                  <v-icon color="success" class="mr-2 mt-1" v-else>mdi-check-circle</v-icon>
                  <div class="mb-2">
                    New password should have atlease 12 character which includes 1 number, 1 special character, 1 uppercase letter & 1 lowercase letter.
                  </div>
                </div>
                <div class="text-right">
                  <v-btn text color="" small elevation="0" to="/" class="mr-2 shadow-off"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary" class="shadow-off"
                    elevation="0" small :disabled="!policymatch"
                    @click="setPassword()"
                    >Set</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
            <v-card class="pa-4" v-else-if="stage === 2">
              <v-card-text class="text-center">
                <v-icon style="font-size: 70px !important" class="success--text"
                  >mdi-check-circle-outline</v-icon
                >
                <div class="title">Password changed successfully</div>
                <div v-if="error" class="pb-2 error--text body-1 text-center">
                  {{ error }}
                </div>
                <div class="pt-3">
                  <div class="pt-3" v-if="gotologin">
                    <div class="d-flex align-center justify-center"><v-btn class="mr-2" icon disabled loading x-small></v-btn>Redirecting to login...</div>
                  </div>
                  <v-btn color="primary" small to="/" v-else
                    ><v-icon left>mdi-home</v-icon>Go to home</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
            <v-card
              :class="{
                'shadow2-a pa-1': true,
                'pa-3': $vuetify.breakpoint.smAndUp,
              }"
              v-else
            >
              <v-card-text class="text-center">
                <noaccess class="pb-3" />
              </v-card-text>
            </v-card>
            <div class="text-center mt-4">
              <!-- remove logo <div class="Caption font-weight-bold">Powered By</div>
               <img
                src="/img/conserve/logotext-no-bg.webp"
                class=""
                style="max-width: 200px"
                alt="consark-image-logo-text-image"
              /> -->
               <div class="text-center mt-2"> 
                   <a href="https://consark.ai/policies/termsofuse/" target="_blank">
                      <v-btn class="shadow-off primary--text mr-2" outlined small>Terms of Use & Service</v-btn>
                  </a>
                   <a href="https://consark.ai/policies/privacypolicy/" target="_blank">
                  <v-btn class="shadow-off primary--text" outlined small
                    >Privicy Policy</v-btn>
                   </a>
                </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
// import loader from "@/views/common/Loader";
import noaccess from "@/views/common/NoAccess";

export default {
  name: "branch",
  props: ["props"],
  components: {
    // loader,
    noaccess,
  },
  data: () => ({
    stage: 1,
    loading: false,
    form: {
      oldpassword: "",
      password: "",
      repassword: "",
      email: "",
      otp: "",
    },
    data: {},
    error: "",
    gotologin: false,
    policymatch: false,
  }),
  created() {
    this.form.email = this.$route.query.u || "";
    this.form.otp = this.$route.query.otp || "";
    if(this.form.email) this.checkSetPassword();
    else if(this.form.otp) {
      this.$store.commit("addLoading");
      this.stage = 1;
      this.$nextTick(()=>{
        this.$store.commit("removeLoading");          
      })
    }
  },
  methods: {
    checkSetPassword: function () {
      this.error = "";
      this.loading = true;
      this.$store.commit("addLoading");
      this.axios
        .post("/v2/auth/checksetpassword/" + this.form.email)
        .then((x) => {
          if (x.data.status === "success") {
            if (x.data.data.length > 0) {
              this.data = x.data.data[0];
              this.stage = 1;
              return;
            }else{
              this.stage = 3
            }
          }else this.error = x.data.message || "Error!";
        })
        .catch((e) => {
          this.stage = 3;
          this.error = e.message || "Error!";
        }).finally(() => {
          this.loading = false;
          this.$store.commit("removeLoading");          
          this.checkpolicy();
        });
    },
    setPassword: function () {
      this.error = "";
      this.loading = true;
      this.axios
        .post("/v2/auth/setpassword", { data: this.form })
        .then((x) => {
          if (x.data.status == "success" && (x.data.data[0] || {}).setpassword) {
            this.stage = 2;
            this.gotologin = true;
            setTimeout(() => {
              window.location.href = "/";
            }, 3000);
          } else {
            this.error = x.data.message || "Error!";
          }
        })
        .catch((e) => {
          this.error = e.message || "Error!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkpolicy: function(){
      let pattern = /(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{12,}/
      this.policymatch = pattern.test(this.form.password);
      // console.log(this.policymatch, this.form.password);
    }
  },
  computed: {},
  watch: {},
};
</script>

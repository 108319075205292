<template>
  <v-app id="app">
    <div class="bg-design-box bg-design-2"></div>
    <div class="bg-design-box bg-design-3"></div>
    <v-main class="bg-image">
      <v-container fluid class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <div class="text-center mb-4">
              <a href="//consark.ai">
                <img
                  src="/img/consark/logo.png"
                  class=""
                  style="max-width: 300px"
                  alt="consark-logo-image"
                />
              </a>
              <div class="body-1 font-weight-bold">
                Unified Controllership Platform
              </div>
            </div>
            <v-card
              :class="{
                'shadow2-a pa-1': true,
                'pa-3': $vuetify.breakpoint.smAndUp,
              }"
              v-if="stage === 'load'"
            >
              <v-card-title primary-title class="justify-center">
                <loader />
              </v-card-title>
            </v-card>
            <v-card
              :class="{
                'shadow2-a pb-1 px-1': true,
                'pb-3 px-3': $vuetify.breakpoint.smAndUp,
              }"
              v-else-if="stage === 'set'"
              loader-height="2"
              :loading="loading"
              :disabled="loading"
            >
              <v-card-text class="pb-1">
                <div class="pt-3 title">Set New Password</div>
                <div class="">
                  <strong>Username: </strong>{{ data.username }}
                </div>
              </v-card-text>
              <v-card-text>
                <v-alert
                  type="error"
                  border="left"
                  v-if="error"
                  text
                  class="py-2"
                  >{{ error }}</v-alert
                >
                <div class="pb-3">Enter your new password below.</div>
                <v-text-field
                  type="password"
                  v-model="form.password"
                  label="New Password"
                  class=""
                  dense
                  outlined
                  prepend-inner-icon="mdi-key-variant"
                  prefix=""
                  suffix=""
                >
                </v-text-field>
                <v-text-field
                  type="password"
                  v-model="form.repassword"
                  label="Re-enter New Password"
                  class=""
                  dense
                  outlined
                  prepend-inner-icon="mdi-key-variant"
                  prefix=""
                  suffix=""
                >
                </v-text-field>
                <div class="text-right">
                  <v-btn text color="mybtn" elevation="0" to="/" class="mr-2"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary mybtn"
                    elevation="0"
                    @click="setPassword()"
                    >Set</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
            <v-card class="pa-4" v-else-if="stage === 'done'">
              <v-card-text class="text-center">
                <v-icon style="font-size: 70px !important" class="success--text"
                  >mdi-check-circle-outline</v-icon
                >
                <div class="title">Password changed successfully</div>
                <div v-if="error" class="pb-2 error--text body-1 text-center">
                  {{ error }}
                </div>
                <div class="pt-3">
                  <v-btn color="primary" elevation="0" to="/"
                    ><v-icon left>mdi-home</v-icon>Go to home</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
            <v-card
              :class="{
                'shadow2-a pa-1': true,
                'pa-3': $vuetify.breakpoint.smAndUp,
              }"
              v-else
            >
              <v-card-text class="text-center">
                <noaccess class="pb-3" />
              </v-card-text>
            </v-card>
            <div class="text-center mt-4">
              <!-- remove logo <div class="Caption font-weight-bold">Powered By</div>
              <img
                src="/img/conserve/logotext-no-bg.webp"
                class=""
                style="max-width: 200px"
                alt="consark-image-logo-text-image"
              /> -->
               <div class="text-center mt-2"> 
                   <a href="https://consark.ai/policies/termsofuse/" target="_blank">
                      <v-btn class="shadow-off primary--text mr-2" outlined small>Terms of Use & Service</v-btn>
                  </a>
                   <a href="https://consark.ai/policies/privacypolicy/" target="_blank">
                  <v-btn class="shadow-off primary--text" outlined small
                    >Privicy Policy</v-btn>
                   </a>
                </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
import loader from "@/views/common/Loader";
import noaccess from "@/views/common/NoAccess";

export default {
  name: "branch",
  props: ["props"],
  components: {
    loader,
    noaccess,
  },
  data: () => ({
    stage: "",
    loading: false,
    form: {
      password: "",
      repassword: "",
      code: "",
    },
    data: {},
    error: "",
  }),
  created() {
    this.form.code = this.$route.query.c || "";
    this.checkSetPassword();
  },
  methods: {
    checkSetPassword: function () {
      this.error = "";
      this.axios
        .post("/v2/auth/checksetpassword/" + this.form.code)
        .then((x) => {
          if (x.data.status === "success" && x.data.data.length > 0) {
            if (x.data.data.length > 0) {
              this.data = x.data.data[0];
              this.stage = "set";
              return;
            }
          }

          this.error = x.data.message || "Error!";
        })
        .catch((e) => {
          this.stage = "";
          this.error = e.message || "Error!";
        });
    },
    setPassword: function () {
      this.error = "";
      this.loading = true;
      this.axios
        .post("/v2/auth/setpassword", { data: this.form })
        .then((x) => {
          if (x.data.status == "success" && x.data.response) {
            this.stage = "done";
          } else {
            this.error = x.data.message || "Error!";
          }
        })
        .catch((e) => {
          this.error = e.message || "Error!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {},
  watch: {},
};
</script>

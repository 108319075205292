<template>
  <div class="">
  <v-app id="app">
    <div class="bg-design-box bg-design-2"></div>
    <div class="bg-design-box bg-design-3"></div>
    <v-main class="bg-image">
      <v-container fluid class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <div class="text-center mb-4">
              <a href="//consark.ai">
                <img
                  src="/img/consark/logo.png"
                  class=""
                  style="max-width: 300px"
                  alt="consark-logo"
                />
              </a>
              <div class="body-1 font-weight-bold">
                Unified Controllership Platform
              </div>
            </div>
            <div v-if="gologin">
              <v-card
                :loading="loadingcard"
                :class="{
                  'mb-4 shadow2-a pb-1 px-1': true,
                  'px-3': $vuetify.breakpoint.smAndUp,
                }"
              >
                <v-card-text class="mt-3">
                  <v-row>
                    <v-col cols="12" class="my-0 py-0">
                      <lb-string
                        v-model="userList.username"
                        label="Name*"
                        :error="userListerror.username"
                      ></lb-string>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <lb-string
                        v-model="userList.contactemail"
                        label="Email*"
                        :error="userListerror.contactemail"
                      ></lb-string>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <lb-string
                        v-model="userList.enityname"
                        label="Entity Name*"
                        :error="userListerror.enityname"
                      ></lb-string>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                        <lb-dropdown
                          v-model="userList.industry"
                          :items="industries"
                          label="Industry*"
                          :error="userListerror.industry"
                        ></lb-dropdown>
                      </v-col>
                    <v-col cols="6" class="my-0 py-0">
                      <lb-dropdown
                        v-model="userList.sector"
                        :items="sectors[userList.industry]"
                        label="Sector*"
                        :error="userListerror.sector"
                      ></lb-dropdown>                        
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                        <lb-dropdown
                          v-model="userList.size"
                          :items="size"
                          label="Size*"
                          :error="userListerror.size"
                        ></lb-dropdown>
                      </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <v-textarea outlined rows="1"
                        v-model="userList.state"
                        label="Address*"
                        :error="userListerror.address"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <lb-string
                        v-model="userList.contactno"
                        label="Mobile"
                         :error="userListerror.contactno"
                      ></lb-string>
                    </v-col>
                    <v-col cols="12">
                      <v-simple-table dense v-if="usersList.length>0">
                        <thead>
                          <tr>
                            <th scope="name">Name</th>
                            <th scope="ref">Email</th>
                            <th scope="location">Contact Number</th>
                            <th scope="action"> Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(v, k) in usersList">
                            <tr :key="k">
                              <!-- <td><v-checkbox class="mt-0" dense label="" v-model="selectgls" multiple :value="v._id"
                              hide-details></v-checkbox></td> -->
                              <td>{{ v.username }}</td>
                              <td>{{ v.contactemail }}</td>
                              <td>{{ v.contactno }}</td>           
                              <td class="text-left">
                                <v-icon @click="deleteUser(v.contactemail)">mdi-delete</v-icon>
                              </td>                   
                            </tr>
                          </template>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-btn
                  class="d-flex justify-end"
                  color="primary ma-0"
                  @click="addUserDialogue=true">
                    Add User
                  </v-btn>
                </v-card-text>
              </v-card>
              <div class="mt-2">
                <v-btn
                  color="primary ma-0"
                  block
                  elevation="0"
                  @click="addself()"
                  >Submit</v-btn
                >
              </div>
            </div>
            <div v-else>
              <v-card
                :class="{
                  'shadow2-a pa-1': true,
                  'pa-3': $vuetify.breakpoint.smAndUp,
                }"
              >
                <v-card-text class="text-center">
                  <v-icon
                    style="font-size: 70px !important"
                    class="success--text"
                    >mdi-check-circle-outline</v-icon
                  >
                  <div class="">
                    We have sent an email to <b>{{ userList.contactemail }}</b
                    >.<br />
                    Please follow the same instructions to email
                  </div>
                  <div class="pt-3">
                    <v-btn color="primary" elevation="0" to="/" small>
                      <v-icon left>mdi-home</v-icon>Go to Login</v-btn >
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <div class="text-center mt-4">
              <div class="text-center mt-2">
                <a
                  href="https://consark.ai/policies/termsofuse/"
                  target="_blank"
                >
                  <v-btn class="shadow-off primary--text mr-2" outlined small
                    >Terms of Use & Service</v-btn
                  >
                </a>
                <a
                  href="https://consark.ai/policies/privacypolicy/"
                  target="_blank"
                >
                  <v-btn class="shadow-off primary--text" outlined small
                    >Privacy Policy</v-btn
                  >
                </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    </v-app>
    <lb-dialog
        v-model="addUserDialogue"
        :heading="'Add User'"
        width="600"
      >
        <template v-slot:body>
          <div>
            <v-row>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string
                    v-model="userData.username"
                    label="Name*"
                    :error="userDataerror.username"
                  ></lb-string>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <lb-string
                    v-model="userData.contactemail"
                    label="Email*"
                    :error="userDataerror.contactemail"
                  ></lb-string>
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <lb-string
                      v-model="userData.contactno"
                      label="Mobile"
                      :error="userDataerror.contactno"
                    ></lb-string>
                  </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="addUser()">
            <span>Add</span>
          </v-btn>
        </template>
      </lb-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userList: {contactno:"0"},
      userListerror: {},
      gologin: true,
      loadingcard: false,
      industries:[
        "Agriculture",
        "Construction",
        "Consumer Services",
        "Business Services",
        "Education",
        "Energy, Utilities & Waste",
        "Finance",
        "Healthcare Services",
        "Holding Companies & Conglomerates",
        "Hospitality",
        "Hospitals & Physicians Clinics",
        "Insurance",
        "Law Firms & Legal Services",
        "Manufacturing",
        "Media & Internet",
        "Minerals & Mining",
        "Organizations",
        "Real estate",
        "Software",
        "Telecommunications",
        "Transportation"
      ],
      sectors:{
        "Agriculture": ["Animals & Livestock", "Crops", "Forestry"],
        "Construction": ["Architecture, Engineering & Design", "Civil Engineering", "Construction", "Commercial & Residential Co"],
        "Consumer Services": ["Automotive Service & Collision Repair", "Barber Shops & Beauty Salons", "Car & Truck Rental", "Childcare", "Cleaning Services", "Funeral Homes & Funeral Related Services", "Landscape Services", "Photography Studio", "Repair Services", "Weight & Health Management"],
        "Business Services":["Accounting Services","Advertising & Marketing","Call Centers & Business Centers","Chambers of Commerce","Commercial Printing","Custom Software & IT Services","Debt Collection","Facilities Management & Commercial Cleaning","Food Service","HR & Staffing", "Information & Document Management","Management Consulting","Multimedia & Graphic Design","Research & Development","Security Products & Services","Translation & Linguistic Services"],
        "Education": ["Colleges & Universities", "K-12 Schools", "Training"],
        "Energy, Utilities & Waste": [
              "Electricity",
              "Oil & Gas Exploration & Services",
              "Waste Treatment, Environmental Services & Recycling",
              "Water Treatment"
            ],
        "Finance": [
          "Banking",
          "Credit Cards & Transaction Processing",
          "Investment Banking",
          "Lending & Brokerage",
          "Venture Capital & Private Equity"
        ],
        "Government": ["Federal", "Local", "State", "Tribal Nations"],
        "Healthcare Services": ["Ambulance Services", "Blood & Organ Banks", "Elderly Care Services", "Medical Laboratories & Imaging Centers", "Mental Health & Rehabilitation Facilities", "Veterinary Services"],
        "Holding Companies & Conglomerates":[],
        "Hospitality":["Amusement Parks", "Arcades & Attractions", "Cultural & Informational Centers", "Fitness & Dance Facilities", "Gambling & Gaming", "Libraries", "Lodging & Resorts", "Movie Theaters", "Museums & Art Galleries", "Performing Arts Theaters", "Restaurants", "Sports Teams & Leagues", "Travel Agencies & Services", "Zoos & National Parks"],
        "Hospitals & Physicians Clinics":["Dental Offices", "Medical & Surgical Hospitals", "Medical Specialists", "Physicians Clinics"],
        "Insurance":[],
        "Law Firms & Legal Services":[],
        "Manufacturing": ["Aerospace & Defense", "Appliances", "Automotive Parts", "Boats & Submarines", "Building Materials", "Chemicals & Related Products", "Cleaning Products", "Computer Equipment & Peripherals", "Cosmetics, Beauty Supply & Personal Care Products", "Electronics", "Food & Beverage", "Furniture", "Glass & Clay", "Hand, Power & Lawn-care Tools", "Health & Nutrition Products", "Household Goods", "Industrial Machinery & Equipment", "Medical Devices & Equipment", "Motor Vehicles", "Pet Products", "Pharmaceuticals", "Photographic & Optical Equipment", "Plastic, Packaging & Containers", "Pulp & Paper", "Sporting Goods", "Telecommunication Equipment", "Test & Measurement Equipment", "Textiles & Apparel", "Tires & Rubber", "Toys & Games", "Watches & Jewelry", "Wire & Cable"],
        "Media & Internet":["Broadcasting", "Data Collection & Internet Portals", "Music Production & Services", "Newspapers & News Services", "Publishing", "Social Networks", "Ticket Sales"],
        "Minerals & Mining":[],
        "Organizations": ["Membership Organizations", "Non-Profit & Charitable Organizations", "Religious Organizations"],
        "Real estate":[],
        "Retail":["Apparel & Accessories Retail", "Auctions", "Automobile Dealers", "Automobile Parts Stores", "Consumer Electronics & Computers Retail", "Convenience Stores, Gas Stations & Liquor Stores", "Department Stores, Shopping Centers & Superstores", "Drug Stores & Pharmacies", "Flowers, Gifts & Specialty Stores", "Furniture", "Grocery Retail", "Home Improvement & Hardware Retail", "Jewelry & Watch Retail", "Office Products Retail & Distribution", "Other Rental Stores (Furniture, A/V, Construction & Industrial Equipment)", "Pet Products", "Record, Video & Book Stores", "Sporting & Recreational Equipment Retail", "Toys & Games", "Vitamins, Supplements & Health"],
        "Software": ["Business Intelligence (BI) Software", "Content & Collaboration Software", "Customer Relationship Management (CRM) Software", "Database & File Management Software", "Engineering Software", "Enterprise Resource Planning (ERP) Software", "Financial Software", "Healthcare Software", "Human Resources Software", "Legal Software", "Mobile App Development", "Multimedia, Games & Graphics Software", "Networking Software", "Security Software", "Storage & System Management Software", "Supply Chain Management (SCM) software"],
        "Telecommunications": ["Cable & Satellite", "Internet Service Providers", "Website Hosting & Internet-related Services", "Telephony & Wireless"],
        "Transportation": ["Airlines", "Airports & Air Services", "Freight & Logistics Services", "Marine Shipping & Transportation", "Rail, Bus & Taxi", "Trucking, Moving & Storage"]
      },
      size: ["0 - 50 million", "50 - 150 million", "$150 - 500 million", "500 to 1bn", "Greater than 1bn"],
      addUserDialogue:false,
      userData:{contactno:"0"},
      userDataerror:{},
      usersList:[],
    };
  },
  created() {
    this.$store.commit("addLoading");
    this.$nextTick(() => {
      this.$store.commit("removeLoading");
    });
  },
  activated(){
    this.refreshPage()
  },
  methods: {
    refreshPage(){
    },
    addself() {
         console.log(this.userList);
        //  this.gologin = false;
        this.loadingcard = true;
        this.userList["subusers"] = this.usersList
        let url = "v2/self_service/add";
        this.axios
          .post(url,this.userList)
          .then((dt) => {
            if (dt.data.status === "success") {
              this.gologin = false;
              this.$store.commit("sbSuccess", "Tasklist item deleted");
            } else {
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0)
                  this.userListerror = dt.data.data[0].index0;
              } else throw new Error(dt.data.message);
            }
          })
          .catch((err) => {
            this.gologin = true;
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loadingcard = false;
          });
    },
    addUser(){
      console.log(this.userData.username)
      if(!this.userData.username){
        this.userDataerror.username="required"
      }
      if (!this.userData.contactemail) {
        this.userDataerror.contactemail = "required"
      }
      if(this.userData.username && this.userData.contactemail){
        this.usersList.push(this.userData);
        this.userData={};
        this.addUserDialogue=false;
        this.userData.contactno="0"
        this.userDataerror=[]
      }
    },
    deleteUser(contactemail){
      this.usersList = this.usersList.filter(obj => obj.contactemail !== contactemail);
    }
  },
};
</script>

<template>
  <div class="all-height d-flex justify-center align-center">
    <div class="text-center d-block">
      <h1 class="display-3 mb-3 font-weight-bold primary--text">OOPS!</h1>
      <div class="title mb-3">404 - Page not found</div>
      <v-btn color="primary" href="/" small><v-icon left>mdi-home</v-icon> Go to home</v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({
    }),
    methods: {
    }
  }
</script>